import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/deploying-with-circleci-orb",
  "date": "8 March 2021",
  "title": "Deploying with the Skpr CircleCI Orb",
  "summary": "Deploying to Skpr is easy with the Skpr CircleCI Orb",
  "author": "Kim Pepper",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "deploying"
  }, {
    "name": "circleci orb"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Deploying to Skpr using the command line has always been easy. Now it's even easier using the `}<a parentName="p" {...{
        "href": "https://circleci.com/developer/orbs/orb/skpr/skpr"
      }}>{`Skpr Circle CI Orb`}</a>{`.`}</p>
    <p>{`Here's the process in a few simple steps:`}</p>
    <h3>{`1. Set up credentials`}</h3>
    <p>{`Add your provided Skpr credentials as CircleCI secrets either directly to the project or via a CircleCI context. They
should be available to the workflow as the following environment variables:`}</p>
    <pre><code parentName="pre" {...{}}>{`SKPR_USERNAME=xxxxxxxxxxxxxxxxxx
SKPR_PASSWORD=yyyyyyyyyyyyyyyyyy
`}</code></pre>
    <h3>{`2. Add the Orb`}</h3>
    <p>{`Add the following to the top of your `}<inlineCode parentName="p">{`.circleci/config.yml`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`orbs:
    skpr: skpr/skpr@1.2.3
`}</code></pre>
    <p>{`NOTE: Check the Orb page to ensure you are using the latest version `}<a parentName="p" {...{
        "href": "https://circleci.com/developer/orbs/orb/skpr/skpr"
      }}>{`Skpr Orb`}</a>{`.`}</p>
    <h3>{`3. Use the Orb`}</h3>
    <p>{`You can now use the Orb in your deployment workflow. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`workflows:
  deploy:
    jobs:
      - skpr/deploy:
          env: dev
          post-deploy:
            - run: skpr exec dev — drush updb -y
`}</code></pre>
    <p>{`In the above example, the `}<inlineCode parentName="p">{`skpr/deploy`}</inlineCode>{` job takes two parameters:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`env`}</inlineCode>{` - The environment to deploy to.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`post-deploy`}</inlineCode>{` - A list of steps to run after deployment.`}</li>
    </ol>
    <p>{`If you have a `}<em parentName="p">{`staging -> approval -> prod`}</em>{` deployment workflow, then you can use the following example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`workflows:
  deploy:
    jobs:
      - skpr/deploy:
          name: deploy_stg
          env: stg
          post-deploy:
            - run: skpr exec stg — drush updb -y
      - approval:
          type: approval
          requires:
            - deploy_stg
      - skpr/deploy:
          name: deploy_prod
          env: prod
          package: false
          post-deploy:
            - run: skpr exec prod — drush updb -y
          requires:
            - approval
`}</code></pre>
    <p>{`In the above example, the `}<em parentName="p">{`prod`}</em>{` deployment does not require packaging as it re-uses the version that was packaged for
the staging environment.`}</p>
    <p>{`The full example is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`workflows:
  deploy:
    jobs:
      - skpr/deploy:
          name: deploy_dev
          context: skpr-example
          env: dev
          post-deploy:
            - run: skpr exec dev — drush updb -y
          filters:
            branches:
              only: master
      - skpr/deploy:
          name: deploy_stg
          context: skpr-example
          env: stg
          post-deploy:
            - run: skpr exec stg — drush updb -y
          filters:
            branches:
              only: releases
      - approve:
          type: approval
          requires:
            - deploy_stg
      - skpr/deploy:
          name: deploy_prod
          context: skpr-example
          env: prod
          package: false
          post-deploy:
            - run: skpr exec prod — drush updb -y
          requires:
            - approve
`}</code></pre>
    <p>{`Of course, you can still use the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/repository/docker/skpr/cli"
      }}>{`skpr/cli`}</a>{` Docker image in your
custom job and run the Skpr `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/deploy/"
      }}>{`commands`}</a>{` `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/shell/"
      }}>{`directly`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      